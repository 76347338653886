const Blog = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://www.marketingtoolslab.com/blog/" 
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="WordPress Blog"
      />
    </div>
  );
};

export default Blog;
